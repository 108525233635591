<template>
  <v-dialog
    v-model:visible="localVisible"
    size="large"
  >
    <template #content>
      <div class="profile-selection">
        <header class="profile-selection__header">
          <h1 class="profile-selection__title">
            Кто будет смотреть?
          </h1>
          <span class="profile-selection__subtitle">Выберите профиль для просмотра</span>
        </header>
        <ul class="profile-selection__list">
          <li
            v-for="{ checked, avatar, id } in avatars"
            :key="id"
            class="profile-selection__item"
          >
            <button class="profile-selection__item-button">
              <profile-avatar
                class="profile-selection__item-avatar"
                :class="{ 'profile-selection__item-avatar--active': checked }"
                :big-checked="checked"
                size="big"
                v-bind="{ checked, avatar }"
                :mark="checked ? 'check' : ''"
              />
              <div class="profile-selection__item-text">
                <h4 class="profile-selection__item-title">
                  Основной
                </h4>
                <p class="profile-selection__item-subtitle">
                  Нет установленных ограничений
                </p>
              </div>
            </button>
          </li>
        </ul>
        <footer class="profile-selection__buttons-wrapper">
          <v-button
            class="profile-selection__button"
            :compressed-medium="mq.phone ?? false"
            @click="addProfile"
          >
            Добавить
            <v-icon name="plus" />
          </v-button>
          <v-checkbox
            v-model:checked="isViewPageProfilesSelection"
            name="approved"
            class="profile-selection__checkbox"
          >
            <template #label>
              Больше не показывать это сообщение
            </template>
          </v-checkbox>
          <v-button
            class="profile-selection__button"
            primary
            :compressed-medium="mq.phone ?? false"
            @click.stop="close"
          >
            Выбрать
          </v-button>
        </footer>
      </div>
    </template>
  </v-dialog>
  <create-profile-dialog v-model:visible="showCreateProfileDialog" />
</template>

<script>
import ProfileAvatar from '@/components/Profile/Avatar.vue';
import VButton from '@/components/common/VButton.vue';
import VCheckbox from '@/components/common/VCheckbox.vue';
import VDialog from '@/components/common/VDialog.vue';
import VIcon from '@/components/common/VIcon.vue';
import CreateProfileDialog from '@/components/Profile/CreateDialog.vue';

export default {
  name: 'EditProfileAvatarDialog',
  components: {
    ProfileAvatar,
    VButton,
    VDialog,
    VCheckbox,
    CreateProfileDialog,
    VIcon,
  },
  inject: ['mq'],
  props: {
    visible: { type: Boolean, default: false },
  },

  emits: ['update:visible', 'close'],
  data() {
    return {
      showCreateProfileDialog: false,
      isViewPageProfilesSelection: false,
      avatars: [
        {
          id: 1,
          checked: true,
          avatar: '',
        },
        {
          id: 2,
          checked: false,
          avatar: '',
        },
        {
          id: 3,
          checked: false,
          avatar: '',
        },
        {
          id: 4,
          checked: false,
          avatar: '',
        },
        {
          id: 5,
          checked: false,
          avatar: '',
        },
      ],
    };
  },
  computed: {
    localVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit('update:visible', value);
      },
    },
  },
  watch: {
    showCreateProfileDialog: {
      handler(value) {
        if (value) {
          this.localVisible = !value;
        } else {
          this.$nextTick(() => {
            this.localVisible = !value;
          });
        }
      },
    },
  },
  methods: {
    close() {
      this.localVisible = false;
    },
    addProfile() {
      this.showCreateProfileDialog = true;
    },
  },
};
</script>

<style lang="scss">
.profile-selection {
  max-width: 560px;
  color: $--white;
  width: 100%;
  background-color: #1c2227;
  border-radius: $--border-radius-large;
  padding: 56px 0 80px;

  @include phone {
    padding: 44px 16px 35px;
  }

  &__header {
    margin-bottom: 32px;
    text-align: center;

    @include phone {
      margin-bottom: 24px;
    }
  }

  &__title {
    font-size: $--font-size-larger-title;
    font-weight: $--font-weight-bold;
    margin-bottom: 24px;
    color: $--white;

    @include phone {
      font-size: $--font-size-subtitle-phone;
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    color: $--white-05percent;
    font-size: $--font-size-subtitle-phone;

    @include phone {
      font-size: $--font-size-small;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-bottom: 30px;
    align-items: center;
  }

  &__item {
    border-radius: 12px;
    padding: 5px;
    transition: $--theme-transition;
    max-width: 400px;
    width: 100%;
    display: flex;
    justify-content: center;

    &:hover {
      background-color: get-theme-for($button, 'primary', 'hover');
    }

    @include phone {
      max-width: 100%;
    }

    &-button {
      display: flex;
      align-items: center;

      @include phone {
        width: 100%;
      }
    }

    &-avatar {
      margin-right: 24px;

      @include phone {
        &:not(.profile-selection__item-avatar--active) {
          margin-left: 4px;
        }
      }
    }

    &-title {
      font-size: $--font-size-subtitle;
      font-weight: $--font-weight-medium;
      line-height: 28px;
      margin-bottom: 4px;
      color: $--white;
      text-align: start;

      @include phone {
        font-size: $--font-size-base;
        margin-bottom: 0;
        line-height: unset;
      }
    }

    &-subtitle {
      font-size: $--font-size-larger;
      line-height: 20px;
      color: $--white-08percent;
      text-align: start;
      white-space: nowrap;

      @include phone {
        font-size: $--font-size-extra-small;
        line-height: unset;
      }
    }
  }

  &__buttons-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__checkbox {
    margin-bottom: 38px;
  }

  &__button {
    width: 336px;

    @include phone {
      width: 100%;
    }

    &:nth-child(1) {
      margin-bottom: 24px;
      color: #364a58;
      font-weight: $--font-weight-regular;
    }
  }
}
</style>
