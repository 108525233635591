<template>
  <v-dialog
    :visible="true"
    size="large"
    @close="$emit('close')"
  >
    <template #content>
      <v-stepper :current-step="settings.defaultAuthMethod === $options.DEFAULT_AUTH_METHODS.LOGIN ? 1 : 2">
        <template #1="{ changeActiveStep }">
          <login-form @auth-success="routerLogging">
            <template #actions>
              <div class="auth-view__form-buttons-wrapper">
                <v-button
                  type="submit"
                  class="auth-view__form-button"
                  primary
                >
                  Войти
                </v-button>
                <v-button
                  class="auth-view__form-button"
                  @click="changeActiveRegistrationStep(changeActiveStep, true)"
                >
                  У меня нет аккаунта
                </v-button>
                <v-button
                  class="auth-view__form-button"
                  @click="changeActiveRegistrationStep(changeActiveStep, false)"
                >
                  Войти по номеру телефона
                </v-button>
              </div>
            </template>
          </login-form>
        </template>
        <template #2="{ changeActiveStep }">
          <registration-send-phone
            :is-registration="isRegistration"
            @start-validate="buttonDis"
            @validated="sendPhone($event, changeActiveStep)"
          >
            <template #actions>
              <footer class="registration-send-phone__form-buttons-wrapper">
                <v-button
                  :disabled="disabledBtn"
                  type="submit"
                  class="registration-send-phone__form-button"
                  primary
                >
                  {{ isRegistration ? 'Зарегистрироваться' : 'Войти' }}
                </v-button>
                <v-button
                  class="registration-send-phone__form-button"
                  @click="changeActiveStep(1)"
                >
                  {{ isRegistration ? 'У меня есть аккаунт' : 'Войти через логин и пароль' }}
                </v-button>
              </footer>
            </template>
          </registration-send-phone>
        </template>
        <template #3="{ changeActiveStep }">
          <registration-send-code
            v-bind="{ phone: localPhone }"
            :is-registration="isRegistration"
            @registration-success="routerLogging"
          >
            <template #actions>
              <div class="registration-send-code__form-buttons-wrapper">
                <v-button
                  class="registration-send-code__form-button"
                  primary
                  @click="changeActiveStep(2)"
                >
                  Назад
                </v-button>
                <v-button
                  type="submit"
                  primary
                  success-text
                  class="registration-send-code__form-button"
                >
                  Отправить
                </v-button>
              </div>
            </template>
          </registration-send-code>
        </template>
        <template #4>
          <profile-selection @close="close" />
        </template>
      </v-stepper>
    </template>
  </v-dialog>
</template>

<script>
import LoginForm from '@/components/common/VLoginForm.vue';
import VDialog from '@/components/common/VDialog.vue';
import VButton from '@/components/common/VButton.vue';
import VStepper from '@/components/common/VStepper.vue';
import RegistrationSendPhone from '@/components/Registration/SendPhone.vue';
import RegistrationSendCode from '@/components/Registration/SendCode.vue';
import ProfileSelection from '@/components/Profile/SelectionDialog.vue';
import { MAIN_ROUTE_NAME } from '@/constants/routes';
import { useSettingsStore } from '../../store/settings';
import { DEFAULT_AUTH_METHODS } from '../../constants/common';

export default {
  DEFAULT_AUTH_METHODS,
  name: 'VAuthDialog',
  components: {
    VButton,
    LoginForm,
    VDialog,
    VStepper,
    RegistrationSendPhone,
    RegistrationSendCode,
    ProfileSelection,
  },
  emits: ['update:visible', 'close'],
  setup() {
    const { settings } = useSettingsStore();
    return { settings };
  },
  data() {
    return {
      visible: false,
      localPhone: '',
      isSelectionProfile: true,
      isRegistration: false,
      disabledBtn: false,
    };
  },

  computed: {
    localVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit('update:visible', value);
      },
    },
  },
  methods: {
    changeActiveRegistrationStep(changeActiveStep, value) {
      changeActiveStep(2);

      this.isRegistration = value;
    },

    buttonDis(val) {
      this.disabledBtn = val ?? true;
    },

    routerLogging() {
      this.$emit('close');
      // this.disabledBtn = false;
      // this.localVisible = false;
      // this.$router.push({ name: MAIN_ROUTE_NAME, params: { isAfterAuthorization: true } });
    },
    back($event, changeActiveStep) {
      changeActiveStep(1);
      this.disabledBtn = false;
    },
    sendPhone(phone, changeActiveStep) {
      this.disabledBtn = false;
      this.localPhone = phone;
      changeActiveStep(3);
    },
  },
};
</script>

<style lang="scss">
.auth-view {
  max-width: 560px;
  width: 100%;
  background-color: #1c2227;
  border-radius: $--border-radius-large;
  padding: 56px 48px 80px;

  @include phone {
    padding: 44px 16px 35px;
  }

  &__header {
    margin-bottom: 32px;
    text-align: center;

    @include phone {
      margin-bottom: 24px;
    }
  }

  &__title {
    font-size: $--font-size-larger-title;
    font-weight: $--font-weight-bold;
    margin-bottom: 24px;

    @include phone {
      font-size: $--font-size-subtitle-phone;
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    color: $--light-gray-300;
    font-size: $--font-size-subtitle-phone;

    @include phone {
      font-size: $--font-size-small;
    }
  }

  &__form {
    &-main {
      margin-bottom: 34px;

      @include phone {
        margin-bottom: 32px;
      }
    }

    &-field {
      &:last-child {
        margin-bottom: 20px;

        @include phone {
          margin-bottom: 16px;
        }
      }
    }

    &-help {
      text-align: center;
    }

    &-buttons-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 64px;
      row-gap: 16px;

      @include phone {
        padding: 0;
        row-gap: 8px;
      }
    }

    &-button {
      width: 100%;

      &:last-child {
        color: $--light-blue;
        font-weight: $--font-weight-regular;
        font-size: $--font-size-button;
      }

      @include phone {
        font-size: $--font-size-subtitle-phone;
        height: 56px;
      }
    }
  }
}
</style>
