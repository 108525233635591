<template>
  <v-dialog
    v-model:visible="localVisible"
    size="large"
  >
    <template #content>
      <div class="create-profile-dialog">
        <header class="profile-selection__header">
          <h1 class="profile-selection__title">
            Создание профиля
          </h1>
        </header>

        <validation-observer v-slot="{ errors, handleSubmit }">
          <v-form @submit.prevent="handleSubmit(createProfile)">
            <template #default>
              <div class="create-profile-dialog__main">
                <create-dialog-summary-section
                  :errors="errors"
                  :section="model.summary"
                  class="create-profile-dialog__section"
                  @change="changeModel"
                />
                <create-dialog-limitations-section
                  :errors="errors"
                  :model="{ ageRating: model.ageRating, timeLimit: model.timeLimit }"
                  class="create-profile-dialog__section"
                  @change="changeModel"
                />
              </div>
            </template>
            <template #footer>
              <footer class="create-profile-dialog__buttons-wrapper">
                <v-button
                  class="create-profile-dialog__button"
                  primary
                  @click="closeDialog"
                >
                  Отменить
                </v-button>
                <v-button
                  type="submit"
                  class="create-profile-dialog__button"
                  primary
                  success-text
                >
                  Создать
                </v-button>
              </footer>
            </template>
          </v-form>
        </validation-observer>
      </div>
    </template>
  </v-dialog>
</template>

<script>
import VForm from '@/components/form/VForm.vue';
import CreateDialogSummarySection from '@/components/Profile/CreateDialogSummarySection.vue';
import VButton from '@/components/common/VButton.vue';
import VDialog from '@/components/common/VDialog.vue';
import { Form as ValidationObserver } from 'vee-validate';
import CreateDialogLimitationsSection from '@/components/Profile/CreateDialogLimitationsSection.vue';

export default {
  name: 'CreateProfileDialog',
  components: {
    VDialog,
    VForm,
    CreateDialogSummarySection,
    CreateDialogLimitationsSection,
    VButton,
    ValidationObserver,
  },
  props: {
    visible: { type: Boolean, default: false },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ['update:visible', 'create-profile'],
  data() {
    return {
      model: {
        summary: {
          profileName: '',
          name: '',
          email: '',
          phone: '',
        },
        ageRating: 18,
        timeLimit: 0,
      },
    };
  },
  computed: {
    localVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      },
    },
  },
  methods: {
    createProfile() {
      this.$emit('create-profile', this.model);
      this.localVisible = false;
    },
    closeDialog() {
      this.localVisible = false;
    },
    changeModel(newModel) {
      this.model = { ...this.model, ...newModel };
    },
  },
};
</script>

<style lang="scss">
.create-profile-dialog {
  padding: 56px 48px 80px;

  @include phone {
    padding: 0 18px;
  }

  &__buttons-wrapper {
    display: flex;
    column-gap: 16px;

    @include phone {
      flex-direction: column-reverse;
      row-gap: 8px;
    }
  }

  &__main {
    margin-bottom: 78px;
    display: flex;
    flex-direction: column;
    row-gap: 56px;

    @include phone {
      margin-bottom: 24px;
    }
  }

  &__button {
    width: 100%;

    @include phone {
      height: 56px;
      font-size: $--font-size-subtitle-phone;
    }
  }
}
</style>
