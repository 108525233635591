<template>
  <create-profile-dialog-section-wrapper title="Заполните данные">
    <v-form-row>
      <v-form-field
        label="Название профиля"
        :error-message="errors.profile"
      >
        <v-input
          :value="localSection.profileName"
          type="text"
          :is-error="errors.profile"
          name="profile"
          rules="required"
          @update:model-value="updatePropertySection('profileName', $event)"
        />
      </v-form-field>
    </v-form-row>
    <v-form-row>
      <v-form-field
        label="Имя пользователя"
      >
        <v-input
          :value="localSection.name"
          type="text"
          name="name"
          @update:model-value="updatePropertySection('name', $event)"
        />
      </v-form-field>
    </v-form-row>
    <v-form-row>
      <v-form-field
        label="Введите почту"
        :error-message="errors.email"
      >
        <v-input
          :value="localSection.email"
          type="text"
          :is-error="errors.email"
          name="email"
          rules="email"
          @update:model-value="updatePropertySection('email', $event)"
        />
      </v-form-field>
    </v-form-row>
    <v-form-row>
      <v-form-field
        label="Введите номер телефона"
      >
        <v-input-phone
          :value="localSection.phone"
          type="text"
          name="phone"
          @update:model-value="updatePropertySection('phone', $event)"
        />
      </v-form-field>
    </v-form-row>
  </create-profile-dialog-section-wrapper>
</template>

<script>
import VFormRow from '@/components/form/VFormRow.vue';
import VFormField from '@/components/form/VFormField.vue';
import VInput from '@/components/common/VInput.vue';
import VInputPhone from '@/components/common/VInputPhone.vue';
import {cloneDeep} from 'lodash-es';
import CreateProfileDialogSectionWrapper from '@/components/Profile/CreateDialogWrapperSection.vue';

export default {
  name: 'CreateDialogSummarySection',
  components: {
    VFormRow,
    VFormField,
    CreateProfileDialogSectionWrapper,
    VInput,
    VInputPhone,
  },
  props: {
    section: {
      type: Object,
      default: () => {
        return {};
      },
    },
    errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ['change'],
  computed: {
    localSection: {
      get() {
        return cloneDeep(this.section);
      },
      set(newValue) {
        this.$emit('change', { summary: { ...this.localSection, ...newValue } });
      },
    },
  },
  methods: {
    updatePropertySection(key, value) {
      this.localSection = { [key]: value };
    },
  },
};
</script>
