// !!!ufo here as revdep of nuxt
import { joinURL, withQuery } from 'ufo';
import { defu } from 'defu';
import md5 from 'crypto-js/md5';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/store/user';
import requestsControl from '@/constants/cacheControl';
import { getExpireDate, updateMacToUuid } from '@/utils/util';
import { parseUserAgent } from '@/utils/util';
import { decrypt } from '@/utils/util';
import { SKEY } from '@/constants/common';
// import consola from 'consola';

// const logger = consola.create({
//   defaults: {
//     tag: 'useapi',
//     type: 'trace',
//     level: 5,
//   },
// });

class ReCache {
  constructor(config) {
    this.config = config;
    this.cache = new Map();
  }
  findCc(path, base = null) {
    const _path = toValue(path);
    const _base = toValue(base);
    const _url = generateUrl(_path, _base, { query: {} });

    let cc;
    for (let [k, v] of this.config) {
      if (k instanceof RegExp) {
        if (k.test(_url.toString())) {
          cc = v;
          break;
        }
      }
      if (_path == k) {
        cc = v;
        break;
      }
    }
    return cc;
  }
  add({ id, path, base, refresh }) {
    this.cache.set(id, { created: Date.now(), path, base, refresh });
  }
  getCache(id) {
    return this.cache.get(id);
  }
  async del(id, refresh = false) {
    clearNuxtData(id);
    this.cache.delete(id);
    if (refresh === true) {
      await refreshNuxtData(id);
      return;
    }
    delete useNuxtApp().payload.data[id];
  }
  getCacheId(path, base = null) {
    const _path = toValue(path);
    const _base = toValue(base);

    for (let [id, cv] of this.cache) {
      const _p = toValue(cv.path);
      const _b = toValue(cv.base);

      if (_p !== _path || _b !== _base) {
        continue;
      }
      return id;
    }
  }
  /**
   *
   * @param {String} id
   * @param {String|Ref} path
   * @param {String|Ref} base
   * @returns
   */
  shouldDrop(id, path, base) {
    const c = this.cache.get(id);
    const ctrl = this.findCc(path, base);
    if (!ctrl || !c) {
      return false;
    }
    if (ctrl.ttl + c.created < Date.now()) {
      return true;
    }
    return false;
  }
  /**
   *
   * @param {String|Ref} path
   * @param {String|Ref} base
   * @param {boolean} [force] do not respect other rules if true (like ttl & stuff from cacheControl)
   * @returns
   */
  async flushDeps(path, base, force = false) {
    const cc = this.findCc(path, base);
    if (!cc.dependencies) {
      return;
    }
    const promises = [];
    for (let d of cc.dependencies) {
      const id = this.getCacheId(d);
      if (!id) {
        continue;
      }
      const cached = this.getCache(id);
      if (force === true || this.shouldDrop(id, cached.path, cached.base)) {
        promises.push(this.del(id, true));
      }
    }

    if (promises.length) {
      await Promise.all(promises);
    }
  }
}

const logger = console;

export const defaultApiQuery = {
  device: 'webclient',
  model: 'emulation',
  firmware: 'webcl',
  tz: new Date().getTimezoneOffset() / 60 + 3,
  api_key: '123ss',
  Mac: '123',
};

export function updateApiQuery(params) {
  Object.assign(defaultApiQuery, params);
}

const rcache = new ReCache(requestsControl);

export { generateUrl, doLogin, doLogout, useApiData };
export default useApi;

/**
 *  talk w/ Moovi's SmartAPI
 *
 * - add params to url: default & profile-specified (age control & stuff)
 * - do (re)login if smartapi asks (separate request)
 * - proxy unauth user's requests
 * - path, base, options.query could be a ref
 *
 * @example useApi('some/path') or useApi('another', {query:{non:'default_param'}})
 *
 * @returns {Promise<Object>} JSON-decoded response from API
 * @param {String|Ref} path
 * @param {{*}} [options={}]
 * @param {String|Ref} [base] smartapi from env if null
 * @param {Boolean} [reauth]
 */
async function useApi(path, options, base = null, reauth = false) {
  const authkeyState = useState('authkey', () => useCookie('authkey').value).value;

  let login = useCookie('login').value;
  login = decrypt(login, SKEY);

  const rc = useRuntimeConfig();
  const proxySkipRegExes = rc.public.proxySkip.map(s => new RegExp(s, 'i'));
  const cqp = useUserStore().contentQueryParams;
  let _options = defu(options, { query: {}, contentQueryParams: true });
  let url, result;
  let _path, _base;

  /*
    Prepare search query params
  */
  _options.query = toValue(_options.query);
  if (_options.params) {
    _options.query = defu(_options.query, _options.params);
    delete _options.params;
  }

  if (_options.contentQueryParams === true) {
    _options.query = { ..._options.query, ...cqp };
  }

  if (authkeyState) {
    _options.query = { ..._options.query, authkey: authkeyState, client_id: login };
  }

  /*
    Prepare base url
  */
  if (base === null) {
    _base = rc.public.baseUrl;
  }
  if (!isRef(base) && base !== null && base !== false) {
    _base = String(base);
  }
  if (isRef(base)) {
    _base = base.value;
  }
  if (!_base && base !== false) {
    console.error('BASE IS EMPTY', path);
    return;
  }

  /*
    Prepare path
  */
  _path = toValue(path);
  if (!_path) {
    console.error('PATH IS EMPTY', path);
    return;
  }

  /*
    Public proxy for unauthorized users
  */
  if (base !== false && !authkeyState && !proxySkipRegExes.some(re => re.test(_base))) {
    _path = `/api/proxy/${_path}`;
    _base = null;
  }

  if (base === false) {
    _base = null;
  }

  /*
    Fetch
  */
  url = generateUrl(_path, _base, _options);
  console.debug('DOREQ/RUN', url);
  result = await $fetch(url);

  // OK response
  if (result.error === 0) {
    return result;
  }

  // Login needed
  if ([1, 3].includes(result.error)) {
    if (reauth === true) {
      throw createError({
        statusCode: 401,
        message: 'Нужно авторизоваться',
        fatal: true,
      });
    }

    await doLogin();
    await nextTick();
    return useApi(path, options, base, true);
  }

  console.log('statusCode', result?.error, 'message', result?.error_description);

  // список кодов ошибок, для отображения пользователю
  const errors = [10];
  // Rest errors
  if (errors.includes(result.error)) {
    showError({
      data: {
        statusCode: result?.error,
        message: result?.error_description
      },
    });
  }
}

/**
 * Helper for using in stores/components
 * Returns data ref & refresh async function, async function cleaning storages
 *
 * @param {String|Ref} path
 * @param {Object} options
 * @param {String|Ref|null} base
 * @returns {{data: Ref, fetch: Function, clean:Function}}
 */
function useApiData(path, options = {}, base = null) {
  let _refresh;
  const _base = toValue(base);
  const _path = toValue(path);
  const nuxt = useNuxtApp();
  const _options = defu(options, {
    immediate: false,
    query: {},
    watch: [],
    getCachedData: key => (nuxt.isHydrating ? nuxt.payload.data[key] : nuxt.static.data[key]),
    before: () => true,
  });
  const query = toValue(_options.query);
  const id = _options.id ? _options.id : generateCacheKey(_path, _base, { ..._options, query });
  const { data: cached } = useNuxtData(id);

  let _watch = _options.watch;

  if (_watch !== false) {
    if (!Array.isArray(_watch)) {
      _watch = [_watch];
    }
    watch(_watch, () => fetch(true));
    _options.watch = false;
  }

  async function fetch(force = false) {
    const cc = rcache.findCc(path, base);
    if (cc?.skip !== true && cached.value) {
      let drop = rcache.shouldDrop(id, path, base);
      if (!(force || drop)) {
        return;
      }
      await rcache.del(id);
    }

    if (cc?.before instanceof Function && cc.before() === false) {
      return;
    }

    if (_options.before instanceof Function && _options.before() === false) {
      return;
    }

    if (_refresh === undefined) {
      _refresh = await _useAsyncData();
    }

    await _refresh({ dedupe: false });
  }

  async function _useAsyncData() {
    const { refresh } = await useAsyncData(
      id,
      async () => {
        const response = await useApi(path, { query: _options.query }, base);
        const cc = rcache.findCc(path, base);

        if (cc?.skip !== false) {
          rcache.add({ id, path, base });
        }
        if (cc?.after instanceof Function) {
          await cc.after.call(rcache, response);
        }
        return response;
      },
      {
        watch: _options.watch,
        transform: _options.transform,
        default: _options.default,
        immediate: _options.immediate,
        getCachedData: _options.getCachedData,
      }
    );
    return refresh;
  }

  async function clean() {
    await rcache.del(id);
  }

  return { data: cached, fetch, clean };
}

/**
 * create url string
 *
 * @param {String} path
 * @param {String} base
 * @param {String} options  for SearchParams use .query or .params
 * @param {Boolean} [withRand] add rand to query string
 * @returns
 */
function generateUrl(path, base, options, withRand = true) {

  if (process.client && base && base.startsWith("http:")) {
    const protocol = window.location.protocol;
    if (protocol === 'https:') {
      base = base.replace("http:", "https:");
    }
  }

  if (process.client) {
    if (navigator?.userAgent && defaultApiQuery.model === 'emulation') {
      const { browser, version } = parseUserAgent(navigator?.userAgent);

      updateApiQuery({
        model: browser,
        firmware: version,
      });
    }
  }

  const searchParams = defu(options.query || options.params || {}, defaultApiQuery);
  if (withRand === true) {
    searchParams.rand = Math.random();
  }
  const uri = joinURL(base, path);

  return withQuery(uri, searchParams);
}

/**
 * @returns void
 */
async function doLogin() {
  const rc = useRuntimeConfig().public;
  let login = useCookie('login').value;
  login = decrypt(login, SKEY);

  let pwd = useCookie('password').value;
  pwd = decrypt(pwd, SKEY);

  const authkey = useCookie('authkey');
  const authkeyState = useState('authkey', () => authkey.value);
  const { user } = storeToRefs(useUserStore());
  // do not store this fields of user object
  const omit = ['authkey', 'utc_time', 'error'];

  // Создаем uuid для Mac если его нет или он равен по умолчанию 123
  updateMacToUuid();

  const url = generateUrl('login', rc.baseUrl, {
    query: {
      client_id: login,
      password: pwd,
    },
  });

  const data = await $fetch(url);
  if (data.error !== 0) {
    logger.error('Login error %d', data.error);
    throw createError({
      statusCode: data.error,
      message: data.error_description,
    });
  }

  user.value = Object.keys(data).reduce((mem, key) => {
    if (!omit.includes(key)) {
      mem[key] = data[key];
    }
    return mem;
  }, {});

  authkeyState.value = data.authkey;
  // authkey.value = data.authkey;
  let expiryDate = getExpireDate(60);
  useCookie('authkey', { expires: expiryDate }).value = data.authkey;

  logger.debug('Login success %s', authkey.value);
}

async function doLogout() {
  let login = useCookie('login');
  const loginDecrypt = decrypt(login.value, SKEY);

  const authkey = useCookie('authkey');
  const pwd = useCookie('password');
  const aks = useState('authkey');
  const { user } = storeToRefs(useUserStore());

  const url = generateUrl('logout', useRuntimeConfig().public.baseUrl, {
    query: {
      client_id: loginDecrypt,
      authkey: authkey.value,
    },
  });

  login.value = undefined;
  pwd.value = undefined;
  aks.value = undefined;
  user.value = {};
  authkey.value = undefined;

  await $fetch(url);

  // @todo: clear user store's `user` object after redirected from Personal Account page
}

/**
 * @param {String} path
 * @param {String} base
 * @param {Object} options
 * @returns
 */
function generateCacheKey(path, base, options) {
  const url = generateUrl(path, base, options, false);
  const hash = md5(url).toString();
  return hash;
}
