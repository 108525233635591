<template>
  <div :class="['v-select', isError && 'v-select--error', multiple && 'v-select--multiple']">
    <vue-select
      ref="select"
      v-bind="$attrs"
      :multiple="multiple"
      :calculate-position="withPopper"
      @open="selectOpen"
    >
      <template #open-indicator="{ attributes }">
        <v-icon
          class="v-select__icon"
          v-bind="attributes"
          name="chevronDown"
          size="24px"
        />
      </template>
      <template #no-options="{ search, searching }">
        <em v-if="loading">Загрузка данных...</em>
        <em v-else-if="searching">Ничего не найдено по запросу: {{ search }}</em>
        <em v-else>Ничего не найдено</em>
      </template>
      <template #selected-option="{ label }">
        <slot
          name="selected-option"
          :label="label"
        />
      </template>
    </vue-select>
  </div>
</template>

<script>
import VueSelect from 'vue-select';
import { createPopper } from '@popperjs/core';
import VIcon from '@/components/common/VIcon.vue';

export default {
  name: 'VSelect',
  components: { VueSelect, VIcon },
  inheritAttrs: false,
  props: {
    // Props for vue-select see here - https://vue-select.org/api/props.html
    isError: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
  },
  computed: {
    // inheritListeners() {
    //   const { blur, ...restListeners } = this.$listeners
    //   // map native event to vue-select event
    //   return blur ? { ...restListeners, 'search:blur': blur } : restListeners
    // },
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      // eslint-disable-next-line no-param-reassign
      dropdownList.style.width = width;

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top');
            },
          },
        ],
      });

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy();
    },
    selectOpen() {
      this.$nextTick(() => {
        this.$refs.select.$refs.dropdownMenu.setAttribute('body-scroll-lock-ignore', true);
      });
    },
  },
};
</script>

<style lang="scss">
// @import 'vue-select/src/scss/vue-select.scss';

.vs {
  &__dropdown-menu {
    z-index: 1000;
    min-width: unset;

    @include phone {
      min-width: unset;
      max-height: 240px;
    }
  }

  &__dropdown-option {
    color: get-theme-for($text, 'secondary');
    font-size: $--font-size-subtitle-phone;

    &--highlight {
      background-color: transparent;
      color: get-theme-for($text, 'primary');
    }
  }
}

.v-select {
  font-size: 16px;
  position: relative;

  &__icon {
    color: transparent;
  }
  &__open-indicator {
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: black transparent transparent transparent;
  }

  .vs__selected {
    display: flex;
    align-items: center;
    // background-color: #f0f0f0;
    border: 1px solid rgba(60, 60, 60, 0.26);
    border-radius: 4px;
    color: #333;
    line-height: 1.4;
    margin: 4px 2px 0 2px;
    padding: 0 0.25em;
    z-index: 0;
  }
  .vs__selected-options {
    display: flex;
    flex-basis: 100%;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 0 2px;
    position: relative;
  }
  .vs__dropdown-menu {
    z-index: 10;
    border-radius: 14px 0 14px 14px;
    top: calc(100% + 10px);
    background-color: #364a58;
    display: block;
    position: absolute;
    left: 0;
    padding: 5px 0;
    width: 100%;
    max-height: 350px;
    overflow-y: auto;
    box-shadow: 0 3px 6px rgb(0 0 0 / 15%);
    // border: 1px solid;
    // border-top-style: none;
    text-align: left;
    list-style: none;
    // background: $--white;
    padding: 12px 16px;
    li {
      cursor: pointer;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .vs__search {
    appearance: none;
    line-height: 1.4;
    font-size: 1em;
    border: 1px solid transparent;
    border-left: none;
    outline: none;
    margin: 4px 0 0 0;
    padding: 0 7px;
    background: none;
    box-shadow: none;
    width: 0;
    max-width: 100%;
    flex-grow: 1;
    z-index: 1;
  }
  .vs__dropdown-toggle {
    padding: 8px 12px;
    background-color: get-theme-for($button, 'primary', 'enabled');
    border-radius: $--main-border-radius;
    border: 1px solid rgba(51, 71, 86, 0.5);
    outline: none;
    width: 100%;
    height: 44px;
    overflow: hidden;
    transition: $--transition-duration;

    &:hover {
      border: 2px solid #263138;
      background: #364a58;
    }
  }

  // &--error {
  //   .vs__dropdown-toggle {
  //     border: 1px solid red;
  //     margin-bottom: 10px;
  //     transition: $--transition-duration;
  //   }
  // }

  .vs__selected-options {
    white-space: nowrap;
    overflow: hidden;
  }

  .vs__selected,
  .vs__search {
    color: get-theme-for($text, 'primary');
    margin: 0;
    padding: 0;
    border: none;
    max-width: 100%;
  }

  .vs__spinner {
    position: absolute;
    top: 8px;
    right: 16px;
    width: 24px;
    height: 24px;
  }

  .vs__clear {
    fill: black;
  }

  .vs__actions {
    padding: 0 2px 0 4px;
    display: flex;
    align-items: center;
  }

  // &--multiple {
  //   .vs__dropdown-toggle {
  //     padding: 4px 12px;
  //   }

  //   .vs__selected-options {
  //     min-height: 30px;
  //   }

  //   .vs__selected {
  //     margin-top: 2px;
  //     margin-right: 4px;
  //     margin-bottom: 2px;
  //     padding: 2px 8px;
  //   }
  // }
}

.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}

.vs__dropdown-toggle {
  appearance: none;
  display: flex;
  padding: 0 0 4px 0;
  background: none;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  white-space: normal;
}
.vs--single.vs--loading .vs__selected,
.vs--single.vs--open .vs__selected {
  position: absolute;
  opacity: 0.4;
}
.vs--single.vs--searching .vs__selected {
  display: none;
}
.v-select .vs__search,
.v-select .vs__selected {
  color: $--white;
}
</style>
