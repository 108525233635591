/*
 *  Initial setup
 *  runs before app started
 */
export default defineNuxtPlugin(nuxtApp => {
  const customerInfoStore = useCustomerInfoStore();
  const channelsListStore = useChannelsListStore();
  const pausedListStore = usePausedListStore();
  const favoritesListStore = useFavoritesListStore();
  const settingsStore = useSettingsStore();
  const collectionsListStore = useCollectionsListStore();
  const vendorStore = useVendorStore();
  const userStore = useUserStore();

  const { user, isAuthenticated, defaultUser } = storeToRefs(userStore);

  /*
    Restore use from localstorage
  */
  if (!Object.keys(user.value).length) {
    try {
      const rawUser = localStorage.getItem('user');
      if (rawUser) {
        user.value = JSON.parse(rawUser);
      }
    } catch {}
  }

  /*
    Persist user to localstorage when changed;
    Update data if users current profile changed;
  */
  watch(
    user,
    async (n, o) => {
      if (!Object.keys(user.value).length) {
        localStorage.removeItem('user');
        return;
      }
      localStorage.setItem('user', JSON.stringify(user.value));

      if (!o) {
        return;
      }

      if (o.current_profile?.id != n.current_profile?.id) {
        await updateSensitiveData();
      }
    },
    {
      immediate: true,
    }
  );

  watch(
    isAuthenticated,
    async a => {
      if (a) {
        return;
      }
      await userStore.fetchDefaultUser();
      user.value = defaultUser.value;
    },
    { immediate: true }
  );

  /*
    Handle non-fatal error (show toast)
   */
  // nuxtApp.hook('vue:error', error => {
  //   console.error(error);
  //   if (error.fatal) {
  //     return;
  //   }

  //   alertService.error({ text: error.message, isIconCross: true, timeout: 15000 });
  //   clearError();
  // });

  async function updateSensitiveData() {
    await Promise.all([
      settingsStore.fetchSettings(true),
      customerInfoStore.fetchCustomer(true),
      channelsListStore.fetchChannels(true),
      pausedListStore.fetchPausedList(true),
      favoritesListStore.fetchFavorites(true),
      collectionsListStore.fetchCollections(true),
      vendorStore.clean(),
      useRecommendedListStore().fetchRecommended(true),
    ]);
  }
});
