import { toBoolean } from '@/utils/typehint';

export const useCustomerInfoStore = defineStore('customerInfo', () => {
  const { data, fetch: fetchCustomer } = useApiData('customer/info', {
    default: () => ({}),
  });
  const customerInfo = computed(() => data.value || {});
  const mainProfile = computed(() => customerInfo.value?.smart_profiles?.find(p => toBoolean(p.is_main) || null));

  async function addProfile(params) {
    const _params = defu(params, {
      title: '',
      name: '',
      email: '',
      showUnsubscribed: false,
      showHint: false,
      phone: [],
      ageRating: '18',
      timeLimit: 0,
    });
    const response = await useApi('profile/add', {
      query: {
        title: _params.title,
        name: _params.name,
        email: _params.email,
        phone_number: _params.phone.slice(1),
        show_unsubscribed: _params.showUnsubscribed,
        show_hint: _params.showHint,
        age_rating: _params.ageRating,
        time_limit: _params.timeLimit,
      },
    });
    fetchCustomer();
    return response.data;
  }

  async function deleteProfile(id) {
    await useApi('profile/delete', { query: { id } });
    fetchCustomer();
  }

  async function changeProfile(params) {
    const _params = defu(params, {
      id: '',
      pin: '',
      name: '',
      title: '',
      phone_number: '',
      email: '',
      age_rating: '18',
      show_unsubscribed: 0,
      time_limit: 0,
      show_hint: 0,
    });
    await useApi('profile/change', { query: _params });
  }

  return {
    customerInfo,
    mainProfile,
    //
    fetchCustomer,
    addProfile,
    deleteProfile,
    changeProfile,
  };
});
