<template>
  <button
    v-if="isButton"
    type="button"
    class="profile-avatar"
    :class="classes"
    :style="backgroundAvatar"
  >
    <img
      v-if="avatar"
      class="profile-avatar__img"
      :src="avatar"
      alt="avatar"
    >
    <v-icon
      v-else
      class="profile-avatar__img"
      :name="'profileAvatarTest2'"
      size="auto"
    />
    <span class="profile-avatar__text">{{ age }}</span>
  </button>
  <div
    v-else
    class="profile-avatar"
    :class="classes"
    :style="backgroundAvatar"
  >
    <slot name="background">
      <img
        v-if="avatar"
        class="profile-avatar__img"
        :src="avatar"
        alt="avatar"
      >
      <v-icon
        v-else
        class="profile-avatar__img"
        :name="'profileAvatarTest2'"
        size="auto"
      />
    </slot>
    <span class="profile-avatar__text">{{ age }}</span>
  </div>
  <!-- <div class="profile-avatar" :class="classes" :style="backgroundAvatar">
    <img :src="avatar" alt="">
    <span class="profile-avatar__text">{{ age }}</span>
  </div> -->
</template>

<script>
import VIcon from '../common/VIcon';
export default {
  name: 'ProfileAvatar',
  components: { VIcon },
  props: {
    age: { type: [String, Number], default: '' },
    size: { type: String, default: '' },
    checked: { type: Boolean, default: false },
    bigChecked: { type: Boolean, default: false },
    isButton: { type: Boolean, default: false },
    avatar: { type: String, default: '' },
    mark: { type: String, default: '' },
  },
  computed: {
    classes() {
      const { size, checked, isButton, mark, bigChecked } = this;
      return {
        [`profile-avatar--size-${size}`]: Boolean(size),
        [`profile-avatar--checked`]: Boolean(checked),
        [`profile-avatar--checked_big`]: Boolean(bigChecked),
        [`profile-avatar--is-button`]: Boolean(isButton),
        [`profile-avatar--mark_${mark}`]: Boolean(mark),
      };
    },
    backgroundAvatar() {
      return `${this.avatar}`;
    },
  },
};
</script>

<style lang="scss">
.profile-avatar {
  width: 40px;
  height: 40px;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 24px;
  object-fit: cover;
  display: flex;
  @include flex-centered;

  &::before {
    content: '';
    border: 4px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 24px;
    transition: 0.3s;
  }
  &__img {
    // border: 4px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 24px;
    overflow: hidden;
    transition: 0.3s;
  }
  &--is-button {
    &:hover {
      &::before {
        border-color: #334756;
      }
    }
  }

  &--mark {
    &_edit,
    &_check {
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 32px;
        height: 32px;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        bottom: -7px;
        right: -10px;
      }
    }

    &_check {
      &::after {
        background-image: url(/assets/icons/checkBlack.svg);
        background-color: $--green;
      }
    }

    &_edit {
      &::after {
        background-image: url(/assets/icons/edit.svg);
        background-color: $--light-gray-200;
      }
    }
  }

  &--size-big {
    width: 72px;
    height: 72px;

    @include phone {
      width: 60px;
      height: 60px;
    }
  }

  &--checked {
    &_big {
      width: 72px;
      height: 72px;

      @include phone {
        width: 68px;
        height: 68px;
      }
    }

    &::before {
      border-color: $--white;
    }
  }

  &__text {
    font-size: 34px;
    line-height: 40px;
    position: relative;
    //font-size: 19px;
    color: get-theme-for($text, 'primary');
    font-weight: $--font-weight-extra-bold;
  }
}
</style>
