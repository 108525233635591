<template>
  <create-profile-dialog-section-wrapper
    title="Ограничения"
    class="create-profile-limitations"
  >
    <div class="create-profile-limitations__row">
      <create-dialog-limitations-section-field
        label="Рейтинг"
        class="create-profile-limitations__select"
      >
        <v-select
          class="create-profile-limitations__select-element"
          :options="ratingOptions"
          :value="localModel.ageRating"
          label="title"
          :reduce="item => item.value"
          :clearable="false"
          @update:model-value="updatePropertySection('ageRating', $event)"
        />
      </create-dialog-limitations-section-field>
      <create-dialog-limitations-section-field
        label="Время просмотра"
        class="create-profile-limitations__select"
      >
        <v-select
          class="create-profile-limitations__select-element"
          :options="viewingTimeOptions"
          :value="localModel.timeLimit"
          label="title"
          :reduce="item => item.value"
          :clearable="false"
          @update:model-value="updatePropertySection('timeLimit', $event)"
        />
      </create-dialog-limitations-section-field>
    </div>
  </create-profile-dialog-section-wrapper>
</template>

<script>
import CreateDialogLimitationsSectionField from '@/components/form/VFormField.vue';
import VSelect from '@/components/common/VSelect.vue';
import CreateProfileDialogSectionWrapper from '@/components/Profile/CreateDialogWrapperSection.vue';
import { useSettingsStore } from '@/store/settings';

export default {
  name: 'CreateDialogLimitationsSection',
  components: {
    CreateProfileDialogSectionWrapper,
    CreateDialogLimitationsSectionField,
    VSelect,
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {
          ageRating: null,
          timeLimit: null,
        };
      },
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const settingsStore = useSettingsStore();

    const localModel = computed({
      get() {
        return props.model;
      },
      set(newModel) {
        emit('change', newModel);
      },
    });

    const selectsOptions = computed(() => {
      return settingsStore.getSettingsSelectsOptions;
    });

    const ratingOptions = computed(() => {
      return selectsOptions.value.profilesAgeRatings;
    });

    const viewingTimeOptions = computed(() => {
      return selectsOptions.value.profilesTimeLimits;
    });

    const updatePropertySection = (key, value) => {
      localModel.value = { [key]: value };
    };

    return {
      selectsOptions,
      localModel,
      ratingOptions,
      viewingTimeOptions,
      updatePropertySection,
    };
  },
};
</script>

<style lang="scss">
.create-profile-limitations {
  &__row {
    display: flex;
    column-gap: 32px;

    @include phone {
      flex-direction: column;
      row-gap: 24px;
    }
  }

  &__select {
    width: 50%;

    @include phone {
      width: 100%;
    }
  }
}
</style>
