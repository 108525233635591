<template>
  <section class="create-profile-dialog-section-wrapper">
    <h3 class="create-profile-dialog-section-wrapper__title">
      {{ title }}
    </h3>
    <slot />
  </section>
</template>

<script>
export default {
  name: 'CreateProfileDialogSectionWrapper',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.create-profile-dialog-section-wrapper {
  &__title {
    font-size: $--font-size-button;
    font-weight: $--font-weight-medium;
    color: $--white;
    line-height: 30px;
    margin-bottom: 32px;
    text-align: center;

    @include phone {
      font-size: $--font-size-base;
      margin-bottom: 24px;
    }
  }
}
</style>
